import { Link } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {WidthContext} from './Layout';
import ResponsiveImage from './ResponsiveImage';

export default function MenuItem({
  menuItemIdx,
  name,
  subItems,
  onItemClick,
  totalItems,
}) {
  const megaMenu = useRef(null);
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);
  const browserWidth = useContext(WidthContext);

  useEffect(() => {
    if (megaMenu.current) {
      let last = menuItemIdx === totalItems - 1;

      if (last) {
        megaMenu.current.style.left = 'auto';
        megaMenu.current.style.right = '0';
        megaMenu.current.classList.add('right-edge');
      } else {
        let offsetRatio = megaMenu.current.offsetWidth / totalItems;
        let offset = offsetRatio * menuItemIdx;
        megaMenu.current.style.left = '-' + offset + 'px';
      }
    }
  }, [megaMenuOpen, totalItems, menuItemIdx]);

  const openMegaMenu = (e) => {
    setMegaMenuOpen(true);
  };

  const closeMegaMenu = (e) => {
    setMegaMenuOpen(false);
  };

  if(browserWidth <= 1199.98) {
    return (
      <>
        {menuItemIdx > 0 && <hr className="main-separator" />}
        {subItems.length === 0 && (
          <a href="/blog" className="menu-link">
            {name}
          </a>
        )}
        {subItems.length > 0 && (
          <Accordion.Item eventKey={menuItemIdx}  key={menuItemIdx}>
            <Accordion.Header>{name}</Accordion.Header>
            <Accordion.Body>
              <ul>
                {subItems.map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <li>
                        {item.linkType === 'a' ? (
                            <a onClick={onItemClick} href={item.link}>
                              {item.name}
                            </a>
                        ) : (
                            <Link onClick={onItemClick} to={item.link}>
                              {item.name}
                            </Link>
                        )}
                      </li>
                      {idx < subItems.length - 1 && <hr/>}
                    </React.Fragment>
                  );
                })}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </>
    );
  } else {
    return (
      <div
          role={'button'}
          tabIndex={0}
        key={menuItemIdx}
        onMouseEnter={openMegaMenu}
        onMouseLeave={closeMegaMenu}
        className={`menu-item${subItems.length > 0 ? '' : ' empty'}`}>
        {subItems.length === 0 && (
          <a href="/blog">
            <p className="quote">{name}</p>
          </a>
        )}
        {subItems.length > 0 && (
          <>
            <p className="quote">{name}</p>
            <div
              ref={megaMenu}
              className={`mega-menu${megaMenuOpen ? '' : ' hidden'}${
                menuItemIdx === 0 ? ' left-edge' : ''
              }`}>
              {subItems.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    {item.linkType === 'a' ? (
                        <a
                            onClick={closeMegaMenu}
                            className="quote"
                            href={item.link}
                            key={idx}
                        >
                          <div className="mega-menu-item-conainer">
                            <ResponsiveImage
                                className="image"
                                imgName={item.imageName}
                                altImg={item.altTag ? item.altTag : ''}
                            />
                            <p className="quote" style={{ lineHeight: '26px' }}>
                              {item.name}
                            </p>
                          </div>{' '}
                        </a>
                    ) : (
                        <Link
                            onClick={closeMegaMenu}
                            className="quote"
                            to={item.link}
                            key={idx}>
                          <div className="mega-menu-item-conainer">
                            <ResponsiveImage
                                className="image"
                                imgName={item.imageName}
                                altImg={item.altTag ? item.altTag : ''}
                            />
                            <p className="quote" style={{ lineHeight: '26px' }}>
                              {item.name}
                            </p>
                          </div>{' '}
                        </Link>
                    )}

                    {idx < subItems.length - 1 && (
                        <div className="separator"></div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}
