import React, { useContext } from 'react';
import logo from '../assets/images/logo.png';
import {WidthContext} from './Layout';
import NavigationMenu from './Menu';
import PatientenLogin from './PatientenLogin';
import expertenIcon from '../assets/icons/Experten-Icon.svg';
import selbsttestIcon from '../assets/icons/Selbsttest-Icon.svg';

export default function Header() {
    const browserWidth = useContext(WidthContext);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if(urlParams.get('open') === 'patientenlogin') {
        window.location.href = 'https://www.mein-leben-mit.info/mywayapp';
        return true;
    }

    if(browserWidth  <= 1199.98) {
        return (
            <header data-header>
                <div>
                    <a href="/" aria-label={'Logo'}>
                        <div className="logo">
                            <img width={62} height={60} src={logo} alt=""/>
                        </div>
                    </a>
                </div>
                <NavigationMenu />
            </header>
        );
    } else {
        return (
          <header data-header>
              <div>
                  <div className="header-row">
                      <a href="/selbsttest" className={'d-flex justify-content-center align-items-center'} aria-label={'Selbsttest'}>
                          <img src={selbsttestIcon} alt="" style={{marginRight: '5px'}}/>
                          Selbsttest
                      </a>
                      <a href="/aerztefinder" className={'d-flex justify-content-center align-items-center'} aria-label={'Ärztefinder'}>
                          <img src={expertenIcon} alt="" style={{marginRight: '5px'}}/>
                          Ärztefinder
                      </a>
                      <PatientenLogin/>
                  </div>
                  <div className="main-container">
                      <a href="/" aria-label={'Logo'}>
                          <div className="logo">
                              <img src={logo} alt=""/>
                          </div>
                      </a>
                      <NavigationMenu />
                  </div>
              </div>
        </header>
        );
    }
}
