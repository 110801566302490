import { Link } from 'gatsby';
import React from 'react';
import caret from '../assets/icons/caret-right.svg';

export default function Button({
    type,
    text,
    to = null,
    style,
    onClick,
    img,
    linkType,
    linkTarget = null
}) {

    if(to === null) {
        return (
            <div style={style} className="havas-button button-anchor">
                <button onClick={onClick} className={type + '-button'}>
                    {text}
                    <img src={img ? img : caret} alt=""/>
                </button>
            </div>
        )
    }

    return (
        <>
        {linkType === 'a' ? (
                <a href={to} target={linkTarget} rel={linkTarget === '_blank' ? 'noreferrer' : null} className="havas-button button-anchor">
                <button onClick={onClick} className={type + '-button'}>
            {text}
            <img src={img ? img : caret} alt="" />
          </button>
        </a>
      ) : (
        <Link style={style} to={to} target={linkTarget} rel={linkTarget === '_blank' ? 'noreferrer' : null} className="havas-button button-anchor">
          <button onClick={onClick} className={type + '-button'}>
            {text}
            <img src={img ? img : caret} alt="" />
          </button>
        </Link>
      )}
    </>
    );
}
