import React, { createContext, useEffect, useState } from 'react';
import BackToTopButton from './BackToTopButton';
import Footer from './Footer';
import Header from './Header';
import QuicklinkMenu from './QuicklinkMenu';

import '../styles/main.scss';

export const MatNrContext = createContext('MAT-DE-2301844-2.0-12/2024');
export const MobileContext = createContext(null);
export const WidthContext = createContext(null);

export default function Layout({ children, className }) {

  const [matNr, setMatNr] = useState('MAT-DE-2301844-2.0-12/2024');
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = parseInt(width) <= 991.98;
  return (
      <MatNrContext.Provider value={{matNr: matNr, setMatNr: setMatNr}}>
        <WidthContext.Provider value={width}>
          <MobileContext.Provider value={isMobile}>
            <Header />
            {/* {<DesktopThemenuebersicht />} */}
            <main className={className}>{children} </main>
            <BackToTopButton />
            <Footer/>
            <QuicklinkMenu />
          </MobileContext.Provider>
        </WidthContext.Provider>
      </MatNrContext.Provider>
  );
}
