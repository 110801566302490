import React, { useContext, useEffect, useState } from 'react';
import { MobileContext } from './Layout';
import Button from './Button';
import HeadingWithColored from './HeadingWithColored';
const exludeLinkList = [
  'https://www.leben-mit-neurodermitis.info/media/EMS/Conditions/Dermatology/Brands/Ad-Revealed-DE/Updates-2020/Aktuelles/Behandlungsmoeglichkeiten.pdf?la=de-DE',
  'https://www.leben-mit-neurodermitis.info/media/EMS/Conditions/Dermatology/Brands/Ad-Revealed-DE/Updates-2020/Aktuelles/Familienratgeber.pdf?la=de-DE',
  'https://www.leben-mit-neurodermitis.info/media/EMS/Conditions/Dermatology/Brands/Ad-Revealed-DE/Updates-2020/Aktuelles/Ratgeber_Arztgespraech.pdf?la=de-DE',
  'https://www.leben-mit-neurodermitis.info/media/EMS/Conditions/Dermatology/Brands/Ad-Revealed-DE/Updates-2020/Aktuelles/Neurodermitis_Patiententagebuch_interaktiv.pdf?la=de-DE',
  'https://www.sanofi.de/',
  'https://www.sanofi.de/de/impressum',
  'https://www.sanofi.de/de/kontakt',
  'https://www.sanofi.de/de/nutzungsbedingungen',
  'https://www.sanofi.de/datenschutz/SADG',
]; //Write here links you don't want popup

export default function Popup() {
  const isMobile = useContext(MobileContext);
  const [openURL, setOpen] = useState(null);

  useEffect(() => {
    let allLinks = Array.from(document.getElementsByTagName('a')).filter(
      (a) => {
        return (
          a.hostname &&
          a.hostname !== window.location.hostname &&
          a.href &&
          !exludeLinkList.includes(a.href)
        );
      }
    );

    allLinks.forEach((link) => {
      link.onclick = (event) => {
        openPopup(event, link.href);
      };
    });
  });

  function openPopup(event, link) {
    event.preventDefault();
    setOpen(link);
    const html = document.querySelector('html');
    html.style.overflow = 'hidden';
    html.style.height = '100%';
  }

  function closePopup() {
    setOpen(null);
    const html = document.querySelector('html');
    html.style.overflow = 'auto';
  }

  return (
    <div className={`popup ${openURL ? '' : 'hidden'}`}>
      <div className="container l-height-desktop">
        <HeadingWithColored
          textList={['Du Verlässt die', 'Website „leben', 'mit neurodermitis“']}
          coloredWordsArr={['Verlässt']}
          className="popupHeading"
        />
        <p>
          Für den Inhalt der folgenden Seite sowie für sich auf dieser Seite
          befindlichen Links zu anderen Websites gilt: Es gibt keinerlei
          Möglichkeit, den Inhalt dieser Seiten zu kontrollieren, da diese
          völlig unabhängig sind. Aus diesem Grund kann keinerlei Verantwortung
          für die Inhalte dieser Websites und die Folgen ihrer Verwendung durch
          die Besucher übernommen werden. Wir bitten Dich aber, uns umgehend auf
          rechtswidrige Inhalte der verlinkten Seiten aufmerksam zu machen.
        </p>
        <div className="button-container">
          <Button
            onClick={() => {
				window.open(openURL, '_blank');
            }}
            text="Fortsetzen"
            type="primary"
          />
          <Button
            onClick={closePopup}
            style={isMobile ? { marginTop: 16, marginBottom: 30 } : {}}
            text="Auf der Seite bleiben"
            type="tertiary"
          />
        </div>

        <svg
          style={{ cursor: 'pointer' }}
          className="popup-icon-close"
          onClick={closePopup}
          xmlns="http://www.w3.org/2000/svg"
          width="35px"
          height="35px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#633BB0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </div>
  );
}
