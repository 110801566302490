import React, { useContext } from 'react';
import loginIcon from '../assets/icons/Login-Icon.svg';
import {WidthContext} from './Layout';

const PatientenLogin = () => {
    const browserWidth = useContext(WidthContext);

    const handleClick = () => {
        window.open('https://www.mein-leben-mit.info/mywayapp', '_blank').focus();
    };

    const mobileText = (
        <>
            <hr className="main-separator"></hr>
            <div
                role={'button'}
                aria-hidden={true}
                onClick={handleClick}>
                <p
                    style={{cursor: 'pointer'}}
                    className="menu-link">
                    Für Patienten
                </p>
            </div>
        </>
    );

    const desktopText = (
        <div
            role={'button'}
            aria-hidden={true}
            onClick={handleClick} className="patient-login-button-container">
            <img src={loginIcon} alt="" />
            <p className="quote">Für Patienten</p>
        </div>
    );

    return (
        <div className="patient-login">
            {browserWidth <= 1199.98 ? mobileText : desktopText}
        </div>
    );
};

export default PatientenLogin;
