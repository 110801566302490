import { Link } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MobileContext } from './Layout';
import ResponsiveImage from './ResponsiveImage';

export default function QuicklinkMenu() {
  const isMobile = useContext(MobileContext);
  const [showElement, setShowElement] = React.useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 2500);

    let scrollTicker;

    const scrollFn = () => {

      let menu = document.getElementById('quicklink-mobile-menu');

      if(!menu) {
        return false;
      }

      menu.classList.add('quicklink-menu-hidden');

      if(scrollTicker) {
        clearTimeout(scrollTicker);
      }

      scrollTicker = setTimeout(() => {
        menu.classList.remove('quicklink-menu-hidden');
      }, 500);
    }

    window.addEventListener('scroll', scrollFn);

    return () => {
      window.removeEventListener('scroll', scrollFn)
    }
  }, []);

  if (!isMobile) {
    return (
      <>
        <Row
          className="quicklink-desktop-menu"
          style={{ zIndex: '100', right: showElement ? '-20px' : '-200px' }}>
          <Col>
            <a href="/selbsttest" aria-label={'Selbsttest'}>
              <Row
                className="quicklink-box text-center"
                style={{
                  borderRight: '2px solid #E7E1F4',
                  borderBottom: '2px solid #E7E1F4',
                }}>
                <Col sm={3} style={{ alignSelf: 'center' }}>
                  <ResponsiveImage
                    loading="eager"
                    className="quicklink-icon p-0 col"
                    imgName="quicklink_question_mark"
                  />
                </Col>
                <Col sm={9} style={{ alignSelf: 'center' }}>
                  <p style={{ textAlign: 'start' }}>Selbsttest</p>
                </Col>
              </Row>
            </a>
            <Link to="/neurodermitis-berater-kontaktieren" aria-label={'Service-Kontakt'}>
              <Row
                className="quicklink-box text-center"
                style={{
                  borderRight: '2px solid #E7E1F4',
                  borderBottom: '2px solid #E7E1F4',
                }}>
                <Col sm={3} style={{ alignSelf: 'center' }}>
                  <ResponsiveImage
                    loading="eager"
                    className="quicklink-icon p-0"
                    imgName="quicklink_service_contact"
                  />
                </Col>
                <Col sm={9} style={{ alignSelf: 'center' }}>
                  <p style={{ textAlign: 'start' }}>Service-Kontakt</p>
                </Col>
              </Row>
            </Link>
            <Link to="/aerztefinder" aria-label={'Ärztefinder'}>
              <Row
                className="quicklink-box text-center"
                style={{
                  borderBottom: '2px solid #E7E1F4',
                }}>
                <Col sm={3} style={{ alignSelf: 'center' }}>
                  <ResponsiveImage
                    loading="eager"
                    className="quicklink-icon p-0 col"
                    imgName="quicklink_expert_finder"
                  />
                </Col>
                <Col sm={9} style={{ alignSelf: 'center' }}>
                  <p style={{ textAlign: 'start' }}>Ärztefinder</p>
                </Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <div
        className="container-fluid quicklink-mobile-menu"
        id={'quicklink-mobile-menu'}
        style={{ backgroundColor: 'white', zIndex: '100' }}>
        <Row>
          <Col
              className="quicklink-box text-center"
              xs={4}
              style={{ borderRight: '2px solid #E7E1F4' }}>
            <Link to="/neurodermitis-berater-kontaktieren" aria-label={'Service-Kontakt'}>
              <ResponsiveImage
                  loading="eager"
                  className="quicklink-icon"
                  imgName="quicklink_service_contact"
              />
              <p>Service-Kontakt</p>
            </Link>
          </Col>
          <Col
            className="quicklink-box text-center"
            xs={4}
            style={{ borderRight: '2px solid #E7E1F4' }}>
            <a href="/selbsttest" aria-label={'Selbsttest'}>
              <ResponsiveImage
                loading="eager"
                className="quicklink-icon"
                imgName="quicklink_question_mark"
              />
              <p>Selbsttest</p>
            </a>
          </Col>
          <Col className="quicklink-box text-center" xs={4}>
            <Link to="/aerztefinder" aria-label={'Ärztefinder'}>
              <ResponsiveImage
                loading="eager"
                className="quicklink-icon"
                imgName="quicklink_expert_finder"
              />
              <p>Ärztefinder</p>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}
