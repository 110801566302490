import parse from 'html-react-parser';
import React from 'react';
import { WrapTextFromArray } from '../helpers/helpers';

export default function HeadingWithColored({
  textList,
  variant,
  color,
  coloredWordsArr,
  style,
  className,
}) {
  if (variant === 'h1') {
    return (
      <div
        style={style}
        className={`heading-with-colored ${className ? className : ''}`}>
        <h1>
          <HeadingList
            coloredWordsArr={coloredWordsArr}
            textList={textList}
            color={color}
          />
        </h1>
      </div>
    );
  } else {
    return (
      <div
        style={style}
        className={`heading-with-colored  ${className ? className : ''}`}>
        <h2>
          <HeadingList
            coloredWordsArr={coloredWordsArr}
            textList={textList}
            color={color}
          />
        </h2>
      </div>
    );
  }
}

export const HeadingList = ({ textList, color, coloredWordsArr }) => {
  return (
    <>
      {textList.map((text, idx) => {
        return (
          <React.Fragment key={idx}>
            {idx !== 0 && <br />}
            {parse(
                WrapTextFromArray(
                    text,
                    coloredWordsArr,
                    'span',
                    `key={${idx}} className="${color ? color : ''}"`,
                    color
                )
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
